import {http, httpAuth} from './config';

export default {
    indexWithState:(state_id) => {
        return http.get('api/v1/cidades/listar/'+ state_id);
    },
    listWithStateOauth:(state_id) => {
        return httpAuth.get('v1/cidades/listar/' + state_id);
    },
    listByCep:(cep) => {
        return httpAuth.get('v1/cidades/cep/' + cep);
    },
    listByCepDisp:(cep) => {
        return http.get('api/v1/cidades/cep/' + cep);
    }
}