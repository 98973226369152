<template>
   <div>
      <div class="phone">
         <label class="font-14" :class="required ? 'required': ''"><i class="icon-phone"/>{{ t('GENERAL.PHONE') }}:
         </label>
         <button data-cy="user_index_add_phone" class="btn btn-primary" type="button" data-target="#addNew"
                 data-toggle="modal" @click="startAdd()" v-if="!isView">
            <i class="icon-plus"/>
         </button>
      </div>

      <div class="row gutters">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <div class="table">
               <div class="row gutters pd-10 table-white bb" v-if="!widthPhone()">
                  <div class="custom-th max-width-type" :class="customClass(0, false, true)">
                     {{ t('GENERAL.PHONE_TYPE') }}
                  </div>
                  <div class="custom-th min-width-number" :class="customClass(1, false, true)">
                     {{ t('COMPANIES.NUMBER') }}
                  </div>
                  <div class="custom-th" :class="customClass(2, false, true)">
                     {{ t('GENERAL.COMMENT') }}
                  </div>
                  <div class="custom-th" v-if="!isView" :class="customClass(3, false, true)">
                     {{ t('ACTIONS.LABEL') }}
                  </div>
               </div>
               <div v-if="!widthPhone() && (phones && phones[0])">
                  <div class="row gutters2" v-for="(phone, key) in phones" :key="phone.id">
                     <div class="custom-td td-phone max-width-type" :class="customClass(0, key)" v-if="types">
                        {{ types.find(t => t.id == phone.type).text }}
                     </div>
                     <div class="custom-td td-phone bt min-width-number" :class="customClass(1, key)">{{
                           phone.number
                        }}
                     </div>
                     <div class="custom-td td-phone bt" :class="customClass(2, key)">{{ phone.description }}</div>
                     <div class="custom-td td-phone bt br-0" :class="customClass(3, key)" v-if="!isView">
                        <button data-cy="user_index_phone_update" class="btn btn-primary mr-2 mb-1" type="button"
                                @click="edit(phone, key)"><i class="icon-edit"/></button>
                        <button data-cy="user_index_phone_delete" class="btn btn-primary mb-1" type="button"
                                @click="deletePhone(key)"><i class="icon-delete"/></button>
                     </div>
                  </div>
               </div>
               <div v-if="widthPhone() && (phones && phones[0])">
                  <div class="bt2"></div>
                  <div class="gutters2 text-left" v-for="(phone, key) in phones" :key="phone.id">
                     <div class="row gutters ml-2">
                        <div class="col-sm-6 col-6" style="padding-top: 0.5rem;">
                           <b class="mt-2">{{ t('GENERAL.PHONE_TYPE') }}</b>
                        </div>
                        <div class="col-sm-6 col-6" style="padding-top: 0.5rem;">
                           <b>{{ t('COMPANIES.NUMBER') }}</b>
                        </div>
                     </div>
                     <div class="row gutters mt-2 ml-2">
                        <div class="col-sm-6 col-6" v-if="types">
                           {{ types.find(t => t.id == phone.type).text }}
                        </div>
                        <div class="col-sm-6 col-6">
                           {{ phone.number }}
                        </div>
                     </div>
                     <div class="row gutters mt-4 ml-2">
                        <div class="col-sm-12 col-12">
                           <b>{{ t('GENERAL.COMMENT') }}</b>
                        </div>
                     </div>
                     <div class="row gutters mt-2 ml-2 mr-2 text-justify">
                        <div class="col-sm-12 col-12">
                           {{ phone.description }}
                        </div>
                     </div>
                     <div class="text-center" v-if="!isView">
                        <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           style="font-size: 20px">
                           <i class="icon-more_horiz"></i>
                        </a>
                        <div
                            class="dropdown-menu  dropdown-menu2 dropdown-menu-phone dropdown-black dropdown-menu-custom">
                           <div data-cy="user_index_phone_update" @click="edit(phone, key)"
                                class="dropdown-item bg-hover-black pointer text-white">
                              <i class="icon-edit"/> {{ t('ACTIONS.UPDATE') }}
                           </div>
                           <div data-cy="user_index_phone_delete" @click="deletePhone(key)"
                                class="dropdown-item bg-hover-black pointer text-white">
                              <i class="icon-delete"/> {{ t('ACTIONS.DELETE') }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div v-if="!phones || phones && !phones[0]">
                  <div class="row gutters2 pd-1 table-grey">
                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        {{ t('GENERAL.MSG.NONE_PHONE_REGISTER') }}
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="firstModal == 'true'" class="validation text-left">
               {{ t('GENERAL.MSG.PLEASE_ADD_A_PHONE_TO_GO_AFTER') }}
            </div>
         </div>
      </div>

      <transition name="fade">
         <div class="modal-mask" v-if="add">
            <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
               <div class="modal-dialog" role="document" style="margin-top: 0rem;">
                  <div class="modal-content">
                     <div class="modal-header modal-header-color">
                        <h4 class="modal-title">{{ t('GENERAL.PHONE') }}</h4>
                        <button type="button" class="closeX" id="closeX" @click="cancel()">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-open">
                        <div class="modal-body modal-row2" style="max-height: calc(100vh - 200px)">
                           <div class="row gutters">
                              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                 <div data-cy="user_index_add_type_phone" class="form-group">
                                    <label for="type" class="required">{{ t('GENERAL.PHONE_TYPE') }}: </label>
                                    <Select2 data-cy="phone_type"
                                             :settings="{width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('GENERAL.TYPE')})}"
                                             :options="types" v-model="type"
                                             id="type" name="type" @select="selectGeneric(type, 'Type')"/>
                                    <div class="validation" id="invalidType" style="display: none">
                                       {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                    </div>
                                 </div>
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                 <div class="form-group">
                                    <label for="phone" class="required">{{ t('COMPANIES.NUMBER') }}: </label>
                                    <input data-cy="profile_update_modal_phone" type="tel" id="phone" name="phone"
                                           class="form-control" placeholder="(00) 00000-0000"
                                           v-model="number" v-mask="['(##) ####-####','(##) #####-####']"
                                           @change.stop.prevent="inputPhone(number, 'phone')"
                                           @keypress="saveWithEnter($event)">
                                    <div class="validation" id="invalidphone" style="display: none">{{
                                          t(msgPhone)
                                       }}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="row gutters">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                 <div class="form-group">
                                    <label>{{ t("GENERAL.COMMENT") }}: </label>
                                    <textarea class="mt-1" style="width: 100%; height: 150px;" v-model="description"
                                              maxlength="60"></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer justify-content-center">
                        <button class="btn btn-secondary mr-4" type="button" @click="cancel()">{{
                              t("ACTIONS.CLOSE")
                           }}
                        </button>
                        <button data-cy="user_index_add_phone_save" class="btn btn-primary" type="button"
                                @click="createPhone()">{{ t("ACTIONS.SAVE") }}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </transition>

   </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';

export default {
   name: 'phone',
   mixins: [Validate],
   props: {
      phonesIndex: {
         type: Array,
         defaut: [],
      },
      isView: {
         type: Boolean,
         defaut: false,
      },
      required: {
         type: Boolean,
         defaut: false,
      },
      isPublic: {
         type: Boolean,
         defaut: false,
      }
   },
   components: {
      Select2,
   },

   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },

   data() {
      return {
         add: false,
         type: null,
         phones: [],
         types: [
             {id: 0, text: 'Whatsapp'},
             {id: 1, text: 'Ligação'},
             {id: 2, text: 'Ligação e Whatsapp'}
         ],
         number: null,
         isEdit: false,
         position: null,
         firstPhone: null,
         description: null,
         firstModal: localStorage.getItem('firstProfileClosed'),
      }
   },

   watch: {
      phonesIndex: function () {
         this.add = false;
         this.type = null;
         this.number = null;
         this.position = null;
         this.description = null;
         this.phones = this.phonesIndex ? this.phonesIndex : [];
         this.firstPhone = localStorage.getItem('firstProfileClosed');
      },
   },

   created() {
      this.phones = this.phonesIndex ? this.phonesIndex : [];
   },

   methods: {
      startAdd() {
         this.add = true;
         this.type = null;
         this.number = null;
         this.isEdit = false;
         this.position = null;
         this.description = null;
      },

      edit(phone, key) {
         this.add = true;
         this.isEdit = true;
         this.position = key;
         this.type = phone.type;
         this.number = phone.number;
         this.description = phone.description;
      },

      createPhone() {
         this.invalid = [];
         this.validatePhone(this.number, 'phone');

         if (this.type == null) {
            this.invalid.push(true);
            document.getElementById("invalidType").style.display = "block";
         } else {
            document.getElementById("invalidType").style.display = "none";
         }

         if (this.invalid.length == 0) {
            if (this.isEdit) {
               this.phones.splice(this.position, 1);
            }

            this.add = false;

            this.phones.push(
                {
                   type: this.type,
                   number: this.number,
                   description: this.description
                }
            );
            this.$emit('update', this.phones);
         }
      },

      deletePhone(key) {
         this.phones.splice(key, 1);

         this.$emit('update', this.phones);
      },

      inputValid(input, id) {
         document.getElementById(id).classList.remove("is-valid");
         document.getElementById(id).classList.remove("is-invalid");
         this.validate(input, id);
      },

      validate(input, id) {
         if (input && (input.length == 14 || input.length == 15)) {
            document.getElementById(id).classList.add("is-valid");
            return true;
         } else {
            document.getElementById(id).classList.add("is-invalid");
            return false;
         }
      },

      saveWithEnter(log) {
         if (log.key == 'Enter') {
            this.createPhone();
         }
      },

      customClass(value, key, isThead = false) {
         let col = '';
         let color = '';

         if (isThead) {
            color = '';
         } else
            color = key % 2 != 0 ? 'table-white' : 'table-grey';

         switch (value) {
            case 0:
               col = !this.isView ? ' col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6' : ' col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6';
               return color + col;
            case 1:
               col = !this.isView ? ' col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6' : ' col-xl-4 col-lg-2 col-md-2 col-sm-6 col-6';
               return color + col;
            case 2:
               col = !this.isView ? ' col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6' : ' col-xl-5 col-lg-7 col-md-7 col-sm-12 col-12';
               return color + col;
            case 3:
               col = ' col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6';
               return color + col;
         }
      },

      cancel() {
         this.add = false;
      }
   }
}
</script>

<style>
.dropdown-menu2 {
   width: 31% !important;
   left: -11px !important;
   min-width: 31% !important;
}

.item-custom {
   padding-top: 6px;
   background: white;
   padding-bottom: 2px;
}

.font-14 {
   font-size: 14px;
}

.red {
   color: red;
}

.btn-dropdown {
   border-radius: 50%;
   padding: 0.2rem 0.5rem;
}

.num-dropdown {
   color: white;
   font-size: 15px;
}

.phone {
   display: flex;
   padding-top: 1%;
   padding-bottom: 3%;
   align-items: center;
   justify-content: space-between;
}

.fade-enter-active, .fade-leave-active {
   transition: opacity 1.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */
{
   opacity: 0;
}

.hide-text {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.table-white {
   background-color: #ffffff !important;
}

.bb {
   border-bottom: 2px solid #d9dee4 !important;
}

.td-phone {
   padding-top: 1rem;
}

.pd-1 {
   padding: 1rem;
}

@media screen and (min-width: 771px) {
   .max-width-type {
      max-width: 24.6%;
   }

   .min-width-number {
      min-width: 17%;
   }
}
</style>
