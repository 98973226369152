import {httpAuth,http} from './config';

export default {
    index:() => {
        return httpAuth.get('v1/empresas');
    },
    show:(id) => {
        return httpAuth.get('v1/empresas/' + id)
    },
    create:(company) => {
        return http.post('api/v1/empresas', company);
    },
    update:(company) => {
        return http.put('api/v1/empresas/' + company.id, company);
    },
    delete: (id) => {
        return httpAuth.delete('v1/empresas/'+ id);
    }
}