<template>
   <div>
      <form @submit.prevent="create">
         <div class="modal-body modal-row2 modal-height-max mt-2" v-if="address">
            <div class="row gutters">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="logo-size logo mb-2"
                       :style="widthPhone() ? 'margin-left: 35% !important' : 'margin-left: 42% !important'">
                     <img class="user-logo" :src="imageCropped ? imageCropped : company.logo"/>
                     <div class="status pointer">
                        <label for="logo"><i class="icon-camera camera2"/></label>
                        <input type="file" id="logo" accept="image/jpeg/*" @change="uploadImage()"
                               style="display: none"/>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row gutters">
               <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
                  <div class="form-group">
                     <label for="taxpayer" class="required">{{ t("COMPANIES.TAXPAYER") }}: </label>
                     <input class="form-control" type="text" name="taxpayer" id="taxpayer"
                            placeholder="000.000.000-00 / 00.000.000/0000-00" v-model="company.taxpayer"
                            v-mask="['###.###.###-##', '##.###.###/####-##']" @keyup="inputTaxpayer(company.taxpayer)"
                            @change="r2CNPJ"/>
                     <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
                  </div>
               </div>
               <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                  <div class="form-group">
                     <label for="email" class="required">{{ t("GENERAL.EMAIL") }}: </label>
                     <input type="text" id="email" name="email" v-model="company.email" class="form-control"
                            placeholder="Endereço de e-mail" @keyup="inputEmail(company.email)"/>
                     <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                  </div>
               </div>
            </div>
            <div class="row gutters">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                     <label for="name" class="required">{{
                             company?.taxpayer?.length === 14 ? 'Nome' : t("COMPANIES.NAME")
                         }}: </label>
                     <input class="form-control" type="text" name="name" id="name" placeholder="Digite o nome"
                            v-model="company.name" @keyup="inputName(company.name)"/>
                     <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
                  </div>
               </div>
            </div>
            <div class="row gutters">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <address-generic :is-company="true" v-if="address"
                                   :addressIndex="address" :isCepRequired="true" :loadCepIndex="loadCep"
                                   @update="updateAddress($event)"/>
               </div>
            </div>
            <div class="row gutters mb-3">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <phone :phonesIndex="company.phones" @update="updatePhone($event)"></phone>
               </div>
            </div>
            <div class="row gutters">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                     <label for="signature">{{ t("COMPANIES.EMAIL_SIGNATURE") }}: </label>
                      <editor :init="configTinyMce"
                              v-model="company.email_signature"
                              @update:modelValue="emailChange($event)"
                              tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js">
                      </editor>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer" style="justify-content: space-between">
            <div>
               <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div>
               <button data-cy="company_create_close" class="btn btn-secondary mr-2" data-dismiss="modal" type="button">
                  {{ t("ACTIONS.CLOSE") }}
               </button>
               <button data-cy="company_create_save" class="btn btn-primary" type="submit">
                  {{ t("ACTIONS.SAVE") }}
               </button>
            </div>
         </div>
      </form>
   </div>
</template>

<script>

import {mapState} from "vuex";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Companies from "@/services/Companies";
import Validate from "@/components/validate.vue";
import phone from '@/components/layouts/phoneComponent.vue';
import addressGeneric from '@/components/layouts/addressComponent.vue';
import Seller from "../../services/Seller";
import Editor from '@tinymce/tinymce-vue';

export default {
   name: "createCompany",
   props: ["companyIndex", "addressIndex"],
   mixins: [Validate],
   components: {
      phone,
      addressGeneric,
       Editor,
   },

   setup() {
      const {t} = useI18n();
      const toast = useToast();
      return {t, toast};
   },

   computed: {
      ...mapState({
         imageCropped: "imageCropped",
      }),
   },

   data() {
      return {
         loadCep: false,
         cities: [],
         states: [],
         invalid: [],
         company: {},
         address: null,
         stateId: null,
         cityName: null,
          configTinyMce: {
              license_key: 'gpl',
              plugins: 'print preview paste searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern  quickbars emoticons',
              menubar: 'file edit format',
              toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
              template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
              template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
              height: 300,
              content_css: 'default',
              language: 'pt_BR',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          },
      };
   },

   mounted() {
      this.$store.commit("changeLoading", true);
      this.$store.commit('setImageCropped', null);

      this.company.phones = [];
      this.company = this.companyIndex;
      this.address = this.addressIndex;

      if (this.address.cities) {
         this.cityName = this.address.cities.name;
         this.address.stateId = this.address.cities.states.name;
      }

      this.$store.commit("changeLoading", false);
   },

   watch: {
      companyIndex: function () {
         this.cityName = null;
         this.company.phones = [];
         this.isCepInvalid = false;
         this.isNameInvalid = false;
         this.isTaxpayerInvalid = false;
         this.company = this.companyIndex;
         this.address = this.addressIndex;

         if (this.address.cities) {
            this.cityName = this.address.cities.name;
            this.address.stateId = this.address.cities.states.name;
         }

         this.$store.commit('setImageCropped', null);

         document.getElementById("cep").classList.remove("is-valid");
         document.getElementById("name").classList.remove("is-valid");
         document.getElementById("email").classList.remove("is-valid");
         document.getElementById("cep").classList.remove("is-invalid");
         document.getElementById("name").classList.remove("is-invalid");
         document.getElementById('invalidEmail').style.display = 'none';
         document.getElementById("email").classList.remove("is-invalid");
         document.getElementById("taxpayer").classList.remove("is-valid");
         document.getElementById("taxpayer").classList.remove("is-invalid");
      },
   },

   methods: {
      r2CNPJ() {
         this.loadCep = false;
         if (this.company.taxpayer.length > 15) {
            this.$store.commit('changeLoading', true);
            let taxpayer = this.company.taxpayer.replace(/\D/g, '');
            Seller.loadCnpj(taxpayer).then((resp) => {
               if (resp.data) {
                  this.company.name = resp.data.razao_social_nome_empresarial;
                  this.company.email = resp.data.estabelecimento[0]?.correio_eletronico;
                  this.address.neighborhood = resp.data.estabelecimento[0]?.bairro;
                  this.address.cep = resp.data.estabelecimento[0]?.cep;
                  if (this.address?.cep?.length > 2){
                     this.loadCep = true;
                  }
                  this.address.street = resp.data.estabelecimento[0]?.logradouro;
                  this.address.number = resp.data.estabelecimento[0]?.numero;
                  this.address.complement = resp.data.estabelecimento[0]?.complemento;
                  this.address.cityName = resp.data.estabelecimento[0]?.municipio?.descricao;
                  this.address.stateName = resp.data.estabelecimento[0]?.uf;
                  if (resp.data.estabelecimento[0]?.ddd2) {
                     this.company.phones = [
                        {
                           type: 0,
                           number: (resp.data.estabelecimento[0]?.ddd1 + resp.data.estabelecimento[0]?.telefone1)
                        },
                        {
                           type: 0,
                           number: (resp.data.estabelecimento[0]?.ddd1 + resp.data.estabelecimento[0]?.telefone2)
                        }
                     ]
                  } else if (resp.data.estabelecimento[0]?.ddd1) {
                     this.company.phones = [
                        {
                           type: 0,
                           number: (resp.data.estabelecimento[0]?.ddd1 + resp.data.estabelecimento[0]?.telefone1)
                        },
                     ]
                  }
               }
               this.$store.commit('changeLoading', false);
            }).catch(() => {this.$store.commit('changeLoading', false)})
         }
      },
      create() {
         this.invalid = [];

         if (this.checkForm()) {
            this.$store.commit("changeLoading", true);
            this.company.address = this.address;
            this.company.logo = this.imageCropped
                ? this.imageCropped
                : this.company.logo;

            if (this.company.id) {
               Companies.update(this.company)
                   .then(() => {
                      this.success();
                      this.toast.success(this.t("GENERAL.MSG.UPDATE_SUCCESS"));
                   })
                   .catch((error) => {
                      this.errorMsg(error);
                      this.$store.commit("changeLoading", false);
                   });
            } else {
               Companies.create(this.company)
                   .then(() => {
                      this.success();
                      this.toast.success(this.t("GENERAL.MSG.REGISTER_SUCCESS"));
                   })
                   .catch((error) => {
                      this.errorMsg(error);
                      this.$store.commit("changeLoading", false);
                   });
            }
         }
      },

      emailChange(emailSignitature) {
         this.company.email_signature = emailSignitature;
      },

      async success() {
         this.$store.commit("setImageCropped", null);
         await this.$store.dispatch("ActionRefreshToken");
         this.$store.getters.sessionStatus;

         document.getElementById('closeX').click();
         this.$store.commit("changeLoading", false);
         this.$emit("created");
      },

      checkForm() {
         this.validateCep(this.address.cep);
         this.nameValidate(this.company.name);
         this.emailValidate(this.company.email);
         this.validateTaxpayer(this.company.taxpayer);

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         return true;
      },

      uploadImage() {
         const file = document.querySelector("input[type=file]").files[0];
         const reader = new FileReader();

         reader.onloadend = () => {
            this.isCropping = true;
            this.$emit("logo", reader.result);
         };

         reader.readAsDataURL(file);
      },

      updatePhone(phones) {
         this.company.phones = phones;
      },

      updateAddress(address) {
         this.company.address = address;
      },
   },
};
</script>

<style>
.user-logo {
   max-width: 110px !important;
   max-height: 110px !important;
   margin-bottom: 1.2rem !important;
}

.logo-size {
   width: 110px !important;
   height: 110px !important;
}

.cropper-size {
   max-width: 350px !important;
   max-height: 275px !important;
}

.logo .status {
   top: 82px;
   right: 2px;
   width: 30px;
   height: 30px;
   font-size: 18px;
   position: absolute;
   border-radius: 15px;
   border: 4px solid #f0f0f0;
}

.logo {
   position: relative;
}

.camera2 {
   right: 1;
   bottom: 0;
   outline: 0;
   cursor: pointer;
   font-size: 23px;
   position: absolute;
   border-radius: 15px;
   background-color: #f0f0f0;
}
</style>
